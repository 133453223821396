html,
body {
  margin: 0;
  font-family:
    'Verdana',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
  font-size: 14px;
  div {
    scrollbar-width: thin;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Verdana';
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/Verdana.woff) format('woff');
}
@font-face {
  font-family: 'Verdana';
  font-style: normal;
  font-weight: bold;
  src: url(./assets/fonts/Verdana-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Verdana';
  font-style: italic;
  font-weight: normal;
  src: url(./assets/fonts/Verdana-Italic.woff) format('woff');
}

.print-charts {
  position: absolute;
  max-width: 700px;
  top: -1000px;
  left: -2500px;
  .MuiPaper-root {
    width: 630px !important;
  }
}

.print-farm-visit-results {
  position: absolute;
  max-width: 700px;
  top: -1000px;
  left: -2500px;
}

@media print {
  @page {
    size: 700px 1191px !important;
  }
  .print-charts,
  .print-farm-visit-results {
    position: absolute;
    top: 0;
    left: 0;
  }
  header,
  .charts-toggle,
  .MuiDrawer-root,
  .feeds-page,
  .comparisons-page {
    display: none !important;
  }

  .farm-visit-results-page {
    > .MuiBox-root {
      display: none;
    }
    .farm-visit-results-page__title,
    .not-printable-farm-visit-results {
      display: none;
    }
  }
}

.apexcharts-tooltip {
  z-index: 1000000 !important;
}
